<template>
  <div class="uk-background-cover uk-height-1-1 layout-background uk-text-left">
    <div class="uk-grid uk-grid-collapse">
      <sidebar
        :data-role="dataRole"
        :type="type"
      />
      <div class="uk-width-expand@s">
        <header-default />
        <router-view :data-role="dataRole" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderDefault from '@/components/globals/header/Default'
import Sidebar from '@/components/globals/sidebar'

export default {
  components: {
    HeaderDefault,
    Sidebar
  },
  props: {
    type: {
      default: 'cms',
      type: String
    }
  },
  data: () => ({
    dataRole: JSON.parse(localStorage.getItem('role_managements'))
  })
}
</script>

<style scoped>
.layout-background {
    background-color: #f2f2f2
}
</style>
