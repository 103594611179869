<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div
    id="toggle-animation"
    :class="{
      'width-large': isShow, 
      'width-small': !isShow, 
      'card-sidebar': true
    }"
  >
    <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
      <div class="logo-default uk-margin-small">
        <img
          :src="
            isShow
              ? `${images}/logo-varion.png`
              : `${images}/logo-varion.png`
          "
          width="159"
          height="38"
          alt=""
        >
        <h3 class="logo-text">
          Admin Dashboard
        </h3>
      </div>
 
      <ul
        v-if="isShow"
        id="sidebar"
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <!-- example static parent-child -->

        <li>
          <a
            id="activity"
            href="/admin/purchase-order-farmer"
            class=""
          >
            <ul
              width="100%"
              data-uk-nav="{multiple:true}"
              class="uk-nav parent-sub"
            >
              <li class="uk-parent">
                <a 
                  class="parent-text"
                >
                  <span class="uk-flex uk-flex-middle">
                    <img
                      src="/images/icon/ic-cash.svg"
                      alt="parent-icon"
                      class="uk-margin-small-right"
                    > Purchase Order
                  </span>
                  <span uk-navbar-parent-icon />
                  <img
                    :src="'/images/icon/ic_chevron_up.svg'"
                    alt="icon-dropdown"
                    class="uk-margin-small-right icon"
                  >
                </a>
                <ul class="uk-nav-sub uk-nav uk-navbar-dropdown-nav">
                  <li :class="getSidebarClass('/admin/purchase-order-farmer')"><a href="/admin/purchase-order-farmer"><span class="sub-icon" /> PO Farmer </a></li>
                  <!-- <li :class="getSidebarClass('/admin/purchase-order-supplier')"><a href="/admin/purchase-order-supplier"><span class="sub-icon" /> PO Supplier </a></li> -->
                </ul>
              </li>
            </ul>
          </a>
        </li>

        <!-- end of example static parent-child -->
        

        <li
          v-for="items in filterSidebar"
          :key="items.name"
        >
          <router-link
            :id="items.identifier"
            :to="items.to"
          >
            <ul
              v-if="checkChildrenAccess(items.children)"
              class="uk-nav parent-sub"
              width="100%"
              data-uk-nav="{multiple:true}"
              @click="showSubMenu($event)"
            >
              <li class="uk-parent">
                <a
                  class="parent-text"
                  @click="parentIconHandler(items.identifier)"
                >
                  <span class="uk-flex uk-flex-middle">
                    <img
                      class="uk-margin-small-right"
                      :src="`${images}/icon/ic_warehouse.svg`"
                      alt="parent-icon"
                    >
                    {{ items.name }}
                  </span>
                  <span uk-navbar-parent-icon />
                  <img 
                    class="uk-margin-small-right icon"
                    :src="`${images}/icon/${ showChildren && items.identifier == identifierClick ? 'ic_chevron_down.svg' : 'ic_chevron_up.svg' }`"
                    alt="icon-dropdown"
                  >
                </a>
                <ul class="uk-nav-sub uk-nav uk-navbar-dropdown-nav">
                  <li
                    v-for="child in items.children"
                    v-if="isCanAccess('view', child.name)"
                    :key="child.name"
                    :class="getSidebarClass(child.to)"
                    @click.prevent="redirectMenu(child.to)"
                  >
                    <span class="sub-icon" />
                    {{ child.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </router-link>
        </li>

        <li
          v-if="isCanAccess('view', 'FARMER')"
          :class="getSidebarClass('/admin/partner')"
        >
          <router-link
            to="/admin/partner"
          >
            <img
              class="uk-margin-small-right"
              :src="`${images}/icon/ic_partner.svg`"
              alt=""
            >
            Farmer List
          </router-link>
        </li>

        <li>
          <router-link
            to="/admin/supplier"
          >
            <img
              class="uk-margin-small-right"
              :src="`${images}/icon/ic_group.svg`"
              alt=""
            >
            Supplier
          </router-link>
        </li>

        <li
          v-if="isCanAccess('view', 'user')"
          class="uk-margin-small-top"
          :class="getSidebarClass('/admin/user')"
        >
          <router-link
            to="/admin/user"
          >
            <img
              class="uk-margin-small-right"
              :src="`${images}/icon/ic_user_list.svg`"
              alt=""
            >
            User List
          </router-link>
        </li>

        <li
          v-if="isCanAccess('view', 'role')"
          class="uk-margin-small-top"
          :class="getSidebarClass('/admin/role')"
        >
          <router-link
            to="/admin/role"
          >
            <img
              class="uk-margin-small-right"
              :src="`${images}/icon/ic_role.svg`"
              alt=""
            >
            Role Management
          </router-link>
        </li>

        <li>
          <router-link
            to="/"
            class="uk-margin-medium-top"
            @click.native="logout"
          >
            <img
              class="uk-margin-small-right"
              :src="`${images}/icon/ic_log_out.svg`"
              alt=""
            >
            <span>Log Out</span>
          </router-link>
        </li>
      </ul>
      
      <ul
        v-else
        class="uk-nav-default uk-nav-parent-icon"
        uk-nav
      >
        <li
          v-for="items in filterSidebar"
          :key="items.name"
          :class="getSidebarClass(items.to)"
        >
          <router-link
            to=""
          >
            <span :uk-icon="items.icon" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from "@/utils/constant"
import { getUserProfile, isCanAccess } from '@/utils/auth'
import sidebarJson from "./sidebar.json"

export default {
  data() {
    return {
      isShow: true,
      showChildren: false,
      images: PREFIX_IMAGE,
      environment: process.env.VUE_APP_ENV,
      sidebar: sidebarJson,
      identifierClick: ''
    }
  },
  computed: {
    filterSidebar: function(){
      const sider = this.sidebar.filter(item => {
        if(item.childDisplay) {
          if (this.environment) {
            if (item.showOn.includes(this.environment)) return item
            return false
          }
          return item
        } else {
          if (this.environment) {
            if (item.showOn.includes(this.environment)) return item
            return false
          }
          return item
        }
      })
      return sider
    },
    userRoleInfo() {
      return getUserProfile()
    }
  },
  methods: {
    isCanAccess(type, menu){
      return isCanAccess(type, menu)
    },
    checkChildrenAccess(child){
      let i=0
      child.forEach(element => {
        if(this.isCanAccess('view', element.name)){
          i=i+1
        }
      })
      return i != 0
    },
    ...mapMutations({
      setMetaUser: 'auth/SET_META'
    }),
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
    showDetailSidebar() {
      this.isShow = !this.isShow
    },
    showSubMenu(event) {
      if (event.target.className == "parent-text" || event.target.className == "uk-margin-small-right icon") {
        // this.showCustomSubMenu(i)
        // this.filterMenu(i)
      }
    },
    getSidebarClass(path){
      return{
        'active': this.$route.fullPath.indexOf(path) > -1,
        'inactive': this.$route.fullPath.indexOf(path) <= -1
      }
    },
    parentIconHandler(identifier){
      if(this.isClicked){
        this.isClicked = !this.isClicked
        this.identifierClick = identifier
        this.showChildren = this.identifierClick && this.isClicked ? true : false
      } else {
        this.isClicked = true
        this.identifierClick = identifier
        this.showChildren = this.identifierClick && this.isClicked ? true : false
      }
    },
    redirectMenu(path) {
      this.$router.push(path)
    },
    goTo(path) {
      switch (path) {
      case '/admin/user':
        this.setMetaUser({page:1, limit:10})
        this.$router.push(path)
        break
      default:
        this.$router.push('/admin/dashboard')
        break
      }
    }
  }
}
</script>

<style scoped>
.width-large {
  width: 20.5%;
}
.width-small {
  width: 7%;
}
#toggle-animation {
  transition: width 500ms;
}
.card-sidebar {
  width: 280px;
  height: 100vh;
}
.detail-sidebar {
  padding-right: 10%;
  background-color: #ffffff;
  height: 50px;
  line-height: 50px;
}
.detail-sidebar a {
  color: #3199ee;
}
.sidebar {
  background-color: #ffffff;
  overflow: auto;
}
.logo-text {
  margin-bottom: 0px !important;
  font-family: 'InterSemiBold';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000000;
}
.uk-nav > li > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.uk-nav > li > a > span {
  color: #828282;
}
.uk-nav-sub li {
  display: flex;
  align-items: center;
  padding: 16px;
}
.uk-nav-sub li a {
  margin-left: 10px;
}
.uk-margin-small-right.icon {
  position: absolute;
  right: -5px;
}
li {
  /* padding: 8px 0; */
  font-family: 'interSemiBold';
  font-weight: 500;
  font-size: 16px;
  color: #828282;
  border-radius: 12px;
}
.parent-sub {
  width: 100%;
}
.uk-card-body > .uk-nav-default .uk-nav-sub {
  padding: 5px 0;
}
.parent-text {
  position: relative;
  color: #828282;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active {
  background-color: #E8FDEC;
  color: #2FAC4A;
  border-radius: 12px;
}
.active > a {
  color: #2FAC4A !important;
}
.active > .sub-icon {
  background-color: #2FAC4A;
}
.sub-icon {
  display: block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #bdbdbd;
  border-radius: 50%;
}
.uk-nav-sub .inactive:hover {
  background-color: #F0F1F4;
  border-radius: 12px;
  color: #828282;
}
.inactive:hover > .sub-icon {
  background-color: #bdbdbd;
}
</style>
