<template>
  <div>
    <nav
      class="uk-navbar-container header-background"
      style="position: relative; z-index: 980;"
      uk-navbar="mode: click"
    >
      <div class="uk-navbar-left">
        <ul class="uk-navbar-nav">
          <li>
            <a
              class="uk-navbar-toggle"
              href="#toggle-animation"
              uk-toggle="target: #toggle-animation; animation: uk-animation-slide-left"
            >
              <span uk-navbar-toggle-icon />
            </a>
          </li>
        </ul>
      </div>
      
      <div class="uk-navbar-right">
        <ul class="uk-navbar-nav">
          <li>
            <a
              class="uk-navbar-toggle"
              href="#"
            >
              <span class="avatar uk-text-bold">{{ user.role ? `${user.role.split(" ")[0].substring(0, 1)}${user.role.split(" ")[1] ? user.role.split(" ")[1].substring(0, 1) : ''}` : 'SA' }}</span>
              <span class="fw-bold uk-margin-small-left">{{ user.role || 'Admin' }}</span>
            </a>
            <div
              class="uk-navbar-dropdown"
              uk-toggle
            >
              <ul
                class="uk-nav-default uk-nav-parent-icon"
                uk-nav
              >
                <li class="uk-nav-header">
                  Account
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getUserProfile } from '@/utils/auth'

export default {
  computed: {
    user() {
      return getUserProfile()
    }
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'auth/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>

<style scoped>
.header-background {
  padding: 0 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.fw-bold {
  font-weight: 600;
}
.uk-navbar-nav > li > a {
  min-height: 60px
}
</style>
